import React, { useEffect, useRef, useState } from "react";
import './ShippingForm.scss';

import UtilityFunction from "../../../../Util/Util";
import { connect } from "react-redux";
import shippingAPI from "../../../../Data/Shipping";

const { numberFormatting } = UtilityFunction;

const mapStateToProps = (state) => {
    return {
        couriers: state.shipping.couriers,
        cart: state.shop.cartProducts
    }
};

const ShippingForm = (props) => {
    const radioRef = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];
    const [ existingCouriers, setExistingCouriers ] = useState([]);

    const chooseShipping = (index, value) => {
        props.onChange(value);
        radioRef[index].current.checked = radioRef[index].current.value == value;
        props.updateShippingFee(existingCouriers[index].price);
    }

    useEffect( () => {
        if( props.couriers.length > 0 && props.cart ) {
            // Call Get Quotation here based on order
            console.log("--Courier--");
            console.log(props.couriers);
            
            (async () => {
                const response = await shippingAPI.getQuotation(
                    "3",
                    props.formData.provinceId, props.formData.regencyId, props.formData.districtId, props.formData.villageId,
                    props.formData.address, 0, 0, props.formData.postalCode, props.cart
                );

                console.log(response);
    
                if( response.hasOwnProperty('errorCode') && response.errorCode === 0 ) {
                    // Attach price to each courier
                    let c = [...props.couriers];
    
                    c = c.map( (item, index) => {
                        if( item.id == "3" ) {
                            item.price = response.data.price;
                        }
    
                        console.log(item);
                        return item;
                    } );
    
                    console.log(c);
    
                    setExistingCouriers([...c]);
                }
            } )();
        }
    }, [props.couriers, props.cart]);
    
    useEffect( () => {
        if( existingCouriers.length > 0 ) {
            radioRef[0].current.checked = radioRef[0].current.value == props.chosenShipping;
            // radioRef[1].current.checked = radioRef[1].current.value == props.chosenShipping;
        }
    }, [existingCouriers]);

    return (
        <div className="shipping-form">
            <h2 className="shipping-form-title">
                Opsi Pengiriman
            </h2>

            <div className="shipping-form-container">
                {
                    existingCouriers.map( (item, index) => {
                        return (
                            <div key={index} className="shipping-form-option">
                                <label htmlFor={ `courier-${index}` }>
                                    <input 
                                        type="radio" 
                                        id={ `courier-${index}` } 
                                        name="shipping" 
                                        value={ item.id } 
                                        onClick={ () => chooseShipping(index, item.id) } 
                                        ref={ radioRef[index] }
                                    /> { item.courier_name }
                                </label>
                                <span className={`description ${ item.price > 0 ? "" : "free" }`}>
                                    { item.price > 0 ? numberFormatting(item.price) : "FREE" }
                                </span>
                            </div>
                        );    
                    } )
                }
            </div>
        </div>
    );
}

export default connect(mapStateToProps)(ShippingForm);