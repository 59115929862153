import React, { useState, useEffect } from 'react';

import productAPI from '../../../../Data/Product';

import './ProductDetail.scss';

import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';
import ProductContent from '../ProductContent/ProductContent';
import LoadingPage from '../../../Utilities/LoadingPage/LoadingPage';
import VariantShowcase from '../VariantShowcase/VariantShowcase';
import useAnalyticsEventTracker from '../../../../GoogleAnalytics/useAnalyticsEventTracker';

const ProductDetail = (props) => {
    const [productDetail, setProductDetail] = useState(false);
    const productCode = props.productCode;

    const { isModal } = props; 

    const CheckProductToGA = () => {
        const gaEventCategory = "Product Type";
    
        const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);
    
        gaEventTracker("Open", productCode.includes("RD-") ? 'Ready Stock' : 'Custom');
    };

    useEffect( () => {
        if(productCode) {
            CheckProductToGA();

            (async () => {
                // const response = await productAPI.productList();
                const response = await productAPI.productDetail(productCode);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    const product = {
                        ...response.data,
                        photos: [ response.data.product.product_image ]
                    };

                    product.product.product_addons = product.product.product_addons != null ? product.product.product_addons : [];

                    for( let index in product.variants ) {
                        let item = product.variants[index];
                        item.product_addons = item.product_addons === null || item.product_addons.length === 0 ? product.product.product_addons : item.product_addons;
                        product.variants[index] = item;
                    }

                    setProductDetail({
                        ...product
                    });
                }
            })();
        }
    }, [productCode]);

    const selectVariant = (variantId) => {
        console.log("Change Variant");
        for( let index in productDetail.variants ) {
            if( productDetail.variants[index].id == variantId ) {
                setProductDetail({
                    ...productDetail,
                    photos: [
                        productDetail.variants[index].variant_image
                    ]
                });
                break;
            }
        }
    }

    return productDetail.hasOwnProperty('product') ? (
        <>
            <div className={`product-detail ${isModal ? '' : 'full-page'}`}>
                <PhotoCarousel photos={productDetail.photos} />
                <ProductContent productDetail={productDetail} selectVariant={ (variantId) => selectVariant(variantId) } />
                {/* <div itemScope itemType="http://schema.org/Product">
                    <meta itemProp="brand" content={productDetail.product_category_name} />
                    <meta itemProp="name" content={`${productDetail.product_name} - ${productDetail.variant_name}`} />
                    <meta itemProp="description" content={ `${productDetail.variant_name}` } />
                    <meta itemProp="productID" content={productCode} />
                    <meta itemProp="url" content={window.location.href} />
                    <meta itemProp="image" content={productDetail.photos[0]} />
                    
                    <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                        <link itemProp="availability" href="http://schema.org/InStock" />
                        <link itemProp="itemCondition" href="http://schema.org/NewCondition" />
                        <meta itemProp="price" content={ productDetail.product_quantities[Object.keys(productDetail.product_quantities)[0]].price } />
                        <meta itemProp="priceCurrency" content="IDR" />
                    </div>
                </div> */}
            </div>
            {/* Showcase variants */}
            {/* {
                productDetail.variants.length > 0 && (
                    <VariantShowcase variants={productDetail.variants} />
                )
            } */}
        </>
    ) : (
        <LoadingPage />
    );
}

ProductDetail.defaultProps = {
    isModal: true
}

export default ProductDetail;

 /**
     * [
     *      {
     *          "id":21,
     *          "product_id":2,
     *          "size_category_id":1,
     *          "product_code":"553558-062",
     *          "variant_name":"Air Jordan 1 Low Black Grey Pink",
     *          "status":1,"condition":"BNIB",
     *          "notes":null,
     *          "created_at":"2022-11-18T15:33:42.000Z",
     *          "updated_at":"2022-11-18T15:33:42.000Z",
     *          "photos":["553558-062_1.png","553558-062_2.png"]
     *          "product_name":"Air Jordan 1 Low",
     *          "product_category_name":"Nike",
     *          "product_quantities":{
     *              "6":{
     *                  "price":3300000,
     *                  "quantity":20,
     *                  "size_name":"9"
     *              },
     *              "21":{
     *                  "price":3000000,
     *                  "quantity":15,
     *                  "size_name":"7.5"
     *              }
     *          },
     *          "size_category_name":"US"
     *      }
     * ]
     */