import * as actionTypes from './shopping-types';
import shoppingCartAPI from '../../Data/ShoppingCart';
import designAttachmentAPI from '../../Data/DesignAttachment';
import { fileManager } from '../../Init/indexedDB';

const INITIAL_STATE = {
    cartProducts: {},
    cartCounter: 0,
    totalQty: 0,
    designAttachment: null
}

const shopReducer = (state = INITIAL_STATE, action) => {
    let { cartProducts, cartCounter, totalQty } = state;
    
    switch(action.type) {
        case actionTypes.ADD_TO_CART:
            /**
             * 1. Get items data from cart
             * 2. Check if item exists
             */
            var { ownerCode, productItem } = action.payload;
            var { productId, sizeId } = productItem;

            if(cartProducts.hasOwnProperty(productId)) {
                if(cartProducts.sizeId !== sizeId) {
                    cartProducts[productId].sizeId = sizeId;
                    cartProducts[productId].quantity = parseInt(productItem.quantity);
                } else {
                    cartProducts[productId].quantity = parseInt(cartProducts[productId].quantity) + parseInt(productItem.quantity);
                }
                // cartProducts[productId].quantity += productItem.quantity;
                cartProducts[productId].addons = productItem.addons;
            } else {
                cartProducts[productId] = productItem;
                cartCounter += 1;
            }

            totalQty += parseInt(productItem.quantity);

            shoppingCartAPI.controller.save(ownerCode, productItem);

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.REMOVE_FROM_CART:
            var { ownerCode, productId } = action.payload;

            if(cartProducts.hasOwnProperty(productId)) {
                delete cartProducts[productId];
                cartCounter -= 1;
                totalQty -= 1;

            }

            shoppingCartAPI.controller.delete(ownerCode, productId);

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.ADJUST_QTY:
            var { ownerCode, productId, qty } = action.payload;

            if(cartProducts.hasOwnProperty(productId)) {
                if(qty >= 0) {
                    cartProducts[productId].quantity = parseInt(qty);
                    shoppingCartAPI.controller.save(ownerCode, cartProducts[productId]);
                } else {
                    delete cartProducts[productId];
                    cartCounter -= 1;
                    shoppingCartAPI.controller.delete(ownerCode, productId);
                }
            }

            totalQty = 0;

            for(let index in cartProducts) {
                totalQty += parseInt(cartProducts[index].quantity);
            }

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.LOAD_CART:
            cartProducts = action.payload.cartData;
            cartCounter = Object.keys(cartProducts).length;
            totalQty = Object.values(cartProducts).reduce( (total, item) => {
                return total + item.quantity;
            }, 0);

            return {
                ...state,
                cartProducts,
                cartCounter,
                totalQty
            };
        case actionTypes.CLEAR_CART:
            shoppingCartAPI.controller.clear(action.payload.ownerCode);

            return {
                ...INITIAL_STATE,
                designAttachment: state.designAttachment
            };

        case actionTypes.LOAD_DESIGN_ATTACHMENT:
            // const designAttachment = designAttachmentAPI.controller.get();

            return {
                ...state,
                designAttachment: action.payload.attachment
            };
        case actionTypes.SAVE_DESIGN_ATTACHMENT:
            // designAttachmentAPI.controller.save(action.payload.attachment);

            return {
                ...state,
                designAttachment: action.payload.attachment
            };
        case actionTypes.CLEAR_DESIGN_ATTACHMENT:
            // designAttachmentAPI.controller.clear();

            return {
                ...state,
                designAttachment: null
            };
        default: 
            return state;
    }
};

export default shopReducer;