import api from './API';

const basePath = 'cart';
const pathList = {
    cart: `/`,
    cartProducts: `/items`,
    cartProductItem: `/:param`,
    cartClear: `/clear`
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const call = {
    getCartProducts: async(productIds, sizeIds, addons, withSizeOptions = 0) => {
        try {
            const apiPath = pathList.cartProducts;
            const cartProducts = await api.getCall(apiPath, null, {
                id: productIds,
                size_id: sizeIds,
                addons: addons,
                with_size_options: withSizeOptions
            });
    
            return cartProducts;
        } catch(e) {
            console.log(e.toString());
        }
    },
    saveCartProducts: async(argToken, argProducts) => {
        try {
            const apiPath = pathList.cartProducts;
            const response = await api.postCall(apiPath, null, {
                products: argProducts
            });

            return response;
        } catch(e) {
            console.log(e.toString());
        }
    },
    removeCartProduct: async(argToken, argProductId) => {
        try {
            const apiPath = pathList.cartProductItem;
            const response = await api.deleteCall(apiPath, argProductId, {}, argToken);

            return response;
        } catch(e) {
            console.log(e.toString());
        }
    },
    loadUserCart: async(argToken) => {
        try {
            const apiPath = pathList.cart;
            const response = await api.getCall(apiPath, null, {}, argToken);

            return response;
        } catch(e) {
            console.log(e.toString());
        }
    },
    addToCart: async (argToken, argProduct) => {
        try {
            const apiPath = pathList.cart;
            const response = await api.postCall(apiPath, null, {
                product: argProduct
            }, argToken);

            return response;
        } catch(e) {
            console.log(e.toString());
        }
    },
    clearCart: async (argToken) => {
        try {
            const apiPath = pathList.cartClear;
            const response = await api.deleteCall(apiPath, null, {}, argToken);

            return response;
        } catch(e) {
            console.log(e.toString());
        }
    }
}

const controller = {
    getAll: () => {
        let storage = localStorage.getItem('shoppingCart');
        
        if(storage === null || storage === '' || storage === '{}') {
            storage = {};
        } else {
            storage = JSON.parse(storage);
        }


        return storage;
    },
    get: (ownerCode = 'guest') => {
        let storage = controller.getAll();

        if(!storage.hasOwnProperty(ownerCode)) {
            storage[ownerCode] = {};
        }

        return storage[ownerCode];
    },
    save: (ownerCode = 'guest', cartItem) => {
        const existingShoppingCart = controller.getAll();
        const { 
            productId, 
            // sizeId, 
            // quantity 
        } = cartItem;

        try {
            
            if(!existingShoppingCart.hasOwnProperty(ownerCode)) existingShoppingCart[ownerCode] = {};
            
            if(!existingShoppingCart[ownerCode].hasOwnProperty(productId)) existingShoppingCart[ownerCode][productId] = {};
            
            existingShoppingCart[ownerCode][productId] = cartItem;        

            localStorage.setItem('shoppingCart', JSON.stringify(existingShoppingCart));

            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    },
    delete: (ownerCode = 'guest', productId) => {
        const existingShoppingCart = controller.getAll();

        try {
            delete existingShoppingCart[ownerCode][productId];

            localStorage.setItem('shoppingCart', JSON.stringify(existingShoppingCart));
    
            return true;
        } catch(e) {
            console.error(e);
            return false;
        }
    },
    clear: (ownerCode) => {
        const storage = controller.getAll();
        storage[ownerCode] = {};
        
        localStorage.setItem('shoppingCart', JSON.stringify(storage));
    }
}

const shoppingCartAPI = {
    call,
    controller
};

export default shoppingCartAPI;