import React, { 
    useState, 
    useEffect, 
    // useRef 
}  from 'react';

import shoppingCartAPI from '../../../Data/ShoppingCart';
import UtilityFunction from '../../../Util/Util';

import { connect } from 'react-redux';
import shoppingCartActions from '../../../Redux/Shopping/shopping-action'; 

import Init from '../../../Init/Init';
import Counter from '../../../Component/Utilities/Counter/Counter';
import CustomSelect from '../../../Component/Utilities/CustomSelect/CustomSelect';
import CheckoutSection from '../../Utilities/CheckoutSection/CheckoutSection';

import NavigationContext from '../../../Context/NavigationContext';

import './ShoppingCart.scss';
import { useContext } from 'react';
import EmptyCart from '../../Utilities/EmptyCart/EmptyCart';

import useAnalyticsEventTracker from '../../../GoogleAnalytics/useAnalyticsEventTracker';

import transactionAPI from '../../../Data/Transaction';
import LoadingPage from '../../Utilities/LoadingPage/LoadingPage';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Shopping Cart';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { call } = shoppingCartAPI;
const { adjustQty, clearCart } = shoppingCartActions;
const { numberFormatting } = UtilityFunction;

const ShoppingCart = (props) => {
    const { adjustQty } = props;
    const [ data, setData ] = useState([]);
    const [ totalPrice, setTotalPrice ] = useState(0);
    const [ totalShippingFee, setTotalShippingFee ] = useState(0);
    const [ onProcess, setOnProcess ] = useState(false);
    const [ cartCounter, setCartCounter ] = useState(0);
    const [ totalQty, setTotalQty ] = useState(0);

    const { FontAwesomeIcon } = Init;
    const { imageFolder } = Init.config;
    const defaultImage = `${imageFolder}/placeholder.jpeg`;

    const navigation = useContext(NavigationContext);

    const { navigate } = navigation;

    const getCartProducts = async (cartData) => {
        let requestData = {
            id: [],
            size_id: [],
            addons: []
        };

        for(let productId in cartData) {
            let item = cartData[productId];

            requestData.id.push(parseInt(productId));
            requestData.size_id.push(item.sizeId);
        
            if( requestData.addons.indexOf(item.addons) === -1 ) {
                requestData.addons.push(parseInt(item.addons));
            }
        }

        if(requestData.id.length > 0 && requestData.size_id.length > 0) {
            const response = await call.getCartProducts(requestData.id, requestData.size_id, requestData.addons);

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                return response.data;
            } 
        }

        return null;
    };

    const bindQuantity = (cartProducts, addons = {}) => {
        const cartData = props.cartData;
        
        for(let index in cartProducts) {
            let cartItem = cartProducts[index];

            if(cartData.hasOwnProperty(cartItem.product_id)) {
                cartItem.quantity = cartData[cartItem.product_id].quantity;
                cartItem.isProduct = cartItem.hasOwnProperty('isProduct') ? cartItem.isProduct : true;

                if( cartData[cartItem.product_id].addons && addons.hasOwnProperty(cartData[cartItem.product_id].addons) ) {
                    cartProducts.push({
                        ...addons[cartData[cartItem.product_id].addons],
                        product_id: cartItem.product_id,
                        quantity: cartItem.quantity,
                        isProduct: false,
                    });
                }
            }

            cartProducts[index] = cartItem;
        }

        setData([...cartProducts]);
    };

    const countTotalPrice = (data) => {
        return data.reduce( (total, item) => {
            let usePrice = item.hasOwnProperty('discount_price') && item.discount_price > 0 ? item.discount_price : item.price;
            
            return total + (item.quantity * usePrice)
        }, 0);
    }

    const getTotal = (total) => {
        // return totalPrice + (totalPrice / 10);
        return totalPrice + totalShippingFee;
    }

    useEffect( () => {
        bindQuantity(data);   
    }, [props.totalQty]);

    useEffect( () => {
        setTotalPrice(countTotalPrice(data));
    }, [data, props.totalQty]);

    useEffect( () => {
        ( async () => {
            const cartData = props.cartData;
            const cartProducts = await getCartProducts(cartData);

            if(cartProducts !== null) {
                const { products, addons } = cartProducts;
                bindQuantity(products, addons);
                // setTotalPrice(countTotalPrice([...products, ...Object.values(addons)]));
            } else {
                setData([]);
                setTotalPrice(0);
            }
        } )();
    }, [props.cartData, props.cartCounter] );

    useEffect( () => {
        console.log(`Cart Counter: ${ props.cartCounter }`);
        setCartCounter(props.cartCounter);
    }, [props.cartCounter] );

    useEffect( () => {
        console.log(`Total Qty: ${ props.totalQty }`);
        setTotalQty(props.totalQty);
    }, [props.totalQty] );

    const checkCounter = (variantKey, quantity) => {
        let productId = data[variantKey].product_id;
        let variantName = data[variantKey].product_name;
        let useMultiColor = props.cartData[productId].useMultiColor;
        let prevQty = props.cartData[productId].quantity;

        const ownerCode = props.identity !== undefined && props.identity.hasOwnProperty('customer_code') ? props.identity.customer_code : 'guest';

        // GaTracker({
        //     action: `Change Counter`,
        //     label: `Cart Product: ${variantName}; Qty: ${quantity}`
        // });
        if( useMultiColor && quantity < 1000 ) {
            quantity = quantity < prevQty ? 0 : 1000;
        }
        
        adjustQty(ownerCode, productId, quantity);
    };

    const checkout = (checkoutData) => {
        checkoutData.transactionItems = props.cartData;

        // GaTracker({
        //     action: `Click Checkout Button`,
        //     label: `Initiate Checkout - ${JSON.stringify(data)}`
        // });

        const transactionData = {
            customerName: checkoutData.customerName,
            phoneNo: checkoutData.phoneNo,
            provinceId: checkoutData.provinceId,
            regencyId: checkoutData.regencyId,
            districtId: checkoutData.districtId,
            villageId: checkoutData.villageId,
            postalCode: checkoutData.postalCode,
            courierType: checkoutData.courierType,
            address: checkoutData.address,
            recipientAccountId: checkoutData.recipientAccountId,
        };

        const usePaymentGateway = checkoutData.usePaymentGateway;

        (async() => {
            setOnProcess(true);
            const response = await transactionAPI.order({
                transactionData,
                transactionItems: props.cartData,
                design_attachment: checkoutData.designAttachment,
                usePaymentGateway
            });

            if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                props.clearCart();
                
                if(usePaymentGateway && response.data.hasOwnProperty('paymentLink') && response.data.paymentLink !== '') {
                    window.open(response.data.paymentLink, "_blank");
                }

                // GaTracker({
                //     action: `Create Transaction`,
                //     label: `Checkout ${ usePaymentGateway ? 'with Xendit' : 'manually' }`
                // });
                
                navigate(`/order/${response.data.transactionCode}`, {
                    replace: true,
                    state: {
                        success: 1
                    }
                });

            } else {
                // Display error if possible;
                setOnProcess(false);
            }
        })();
    }

    return (
        <>
            {
                cartCounter > 0 && (
                    <CheckoutSection id="section-2" 
                        checkout={ (checkoutData) => checkout(checkoutData) } 
                        onProcess={ onProcess } 
                        allowToProceed={ totalQty > 0 }
                        updateShippingFee={ (fee) => setTotalShippingFee(fee) }
                    />
                )
            }
            {
                data !== null &&
                data.length > 0 ? (
                    <div id={props.id} className={`shopping-cart-section`}>
                        <h1 className="shopping-cart-title">
                            Summary           
                        </h1>
                        <h3 className='shopping-cart-subtitle'>
                            Shopping Cart
                        </h3>
                        <span className='shopping-cart-caption'>
                            You have { data.length } items in your cart
                        </span>
                        <table className='shopping-cart-table'>
                            {/* <thead>
                                <tr>
                                    <th colSpan={2}> Product </th>
                                    <th> Quantity </th>
                                    <th> Price </th>
                                </tr>
                            </thead> */}
                            <tbody>
                                {
                                    data !== null &&
                                    data.length > 0 &&
                                    data.map( (v, k) => {
                                        const photoPath = v.variant_image ? `${v.variant_image}` : defaultImage

                                        return (
                                            <tr key={k}>
                                                <td className='product-photo'>
                                                    <img src={photoPath} alt="" />
                                                </td>
                                                <td className='product-name'>
                                                    <span className='name'>
                                                        { v.product_name }
                                                    </span>
                                                    <span className="variant">
                                                        { v.size_name }
                                                    </span>
                                                </td>
                                                {
                                                    v.isProduct ? (
                                                        <td className='product-qty'>
                                                            <Counter 
                                                                size={'sm'} 
                                                                customClass="product-qty-counter" 
                                                                baseValue={v.quantity} 
                                                                minValue={ (v.moq) * -1 }
                                                                maxValue={1000}
                                                                onModify={(quantity) => checkCounter(k, quantity)} 
                                                                useMultiColor={ props.cartData.hasOwnProperty(v.product_id) && props.cartData[v.product_id].hasOwnProperty("useMultiColor") ? props.cartData[v.product_id].useMultiColor : false }
                                                            />
                                                        </td>
                                                    ) : (
                                                        <td className='product-qty'>
                                                            { 
                                                                // v.product_id
                                                                props.cartData.hasOwnProperty(v.product_id) ? props.cartData[v.product_id].quantity : ""
                                                                // v.quantity 
                                                            }
                                                        </td>
                                                    )
                                                }
                                                <td className='product-price'>
                                                    {
                                                        v.hasOwnProperty('discount_price') && v.discount_price > 0 ? (
                                                            <>
                                                                {/* <span className='price discounted'> { numberFormatting(v.price) } </span> <span className="price"> { numberFormatting(v.discount_price) } </span> */}
                                                                <span className='price'>
                                                                    {
                                                                        numberFormatting(v.discount_price * v.quantity)
                                                                    }
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className='price'>
                                                                {
                                                                    numberFormatting(v.price)
                                                                }
                                                            </span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    } )
                                }
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr className='total-info'>
                                    <td className='total-label' colSpan={3}> Subtotal </td>
                                    <td className='total-value'> { numberFormatting(totalPrice) } </td>
                                </tr>
                                {/* <tr className='total-info'>
                                    <td className='total-label' colSpan={3}> Pajak/Tax (10%) </td>
                                    <td className='total-value'> { numberFormatting(totalPrice / 10) } </td>
                                </tr> */}
                                <tr className='total-info'>
                                    <td className='total-label' colSpan={3}> Ongkos Kirim </td>
                                    <td className='total-value'> { totalShippingFee > 0 ? numberFormatting(totalShippingFee) : "-" } </td>
                                </tr>
                                <tr className='total-info bold'>
                                    <td className='total-label' colSpan={3}> Total </td>
                                    <td className='total-value'> { numberFormatting(getTotal(totalPrice)) } </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <EmptyCart />
                )
            }
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity,
        cartData: state.shop.cartProducts,
        cartCounter: state.shop.cartCounter,
        totalQty: state.shop.totalQty
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        adjustQty: (ownerCode, productId, quantity) => dispatch(adjustQty(ownerCode, productId, quantity)),
        clearCart: () => dispatch(clearCart())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);