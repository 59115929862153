import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useRoutes, Link } from 'react-router-dom';

import ErrorPage from '../ErrorPage/ErrorPage';

import PrimaryButton from '../../Component/Utilities/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../Component/Utilities/SecondaryButton/SecondaryButton';
import FormInput from '../../Component/Utilities/FormInput/FormInput';

import Init from '../../Init/Init';
import UtilityFunction from '../../Util/Util';

import authAPI from '../../Data/Auth';
import transactionAPI from '../../Data/Transaction';

import { connect } from 'react-redux';
import { getAuthenticatedUser } from '../../Redux/Auth/auth-action';

import './ProfilePage.scss';
import LoadingPage from '../../Component/Utilities/LoadingPage/LoadingPage';
import CustomToast from '../../Component/Utilities/Toast/Toast';
import AddressSection from '../../Component/PagesComponent/AddressSection/AddressSection';

import useAnalyticsEventTracker from '../../GoogleAnalytics/useAnalyticsEventTracker';

const GaTracker = ({
    action,
    label
}) => {
    const gaEventCategory = 'Profile';

    const gaEventTracker = useAnalyticsEventTracker(gaEventCategory);

    gaEventTracker(action, label);
};

const { FontAwesomeIcon } = Init;
const { objectIsEmpty, numberFormatting, getFormattedDatetime } = UtilityFunction;
const { updateAccount } = authAPI;

const mapStateToProps = (state) => {
    return {
        identity: state.auth.identity
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getIdentity: () => dispatch(getAuthenticatedUser())
    };
}

const MyAccount = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [toastContent, setToastContent] = useState({
        title: '',
        content: '',
        status: null,
        show: false
    });

    useEffect(() => {
        const { state } = location;
        const content = toastContent;

        if(state !== null && !objectIsEmpty(state) && state.hasOwnProperty('saveSuccess')) {
            if(state.saveSuccess) {
                content.title = 'Success';
                content.content = state.message;
                content.status = state.saveSuccess;
                content.show = true;
            }

            setToastContent({
                ...content
            });
        }
    }, []);

    const modifyAccount = () => {
        GaTracker({
            action: `Click edit account`,
            label: `Move to account form to edit account`
        });

        navigate('/profile/edit-account');
    };

    const closeToast = () => {
        setToastContent({
            ...toastContent,
            show: false
        });
    }

    return (
        <>
            <CustomToast show={toastContent.show} 
                onClose={closeToast} 
                title={toastContent.title} 
                status={toastContent.status}
                customClass="account-modify-toast"
            >
                <div className="content">
                    { toastContent.content }
                </div>
            </CustomToast>
            <div className="customer-account-detail">
                <div className="account-item">
                <span className="account-label">Nama Pengguna </span> <span className="account-data">{ props.identity.fullname }</span> 
                </div>
                
                <div className="account-item">
                <span className="account-label">Email </span> <span className="account-data">{ props.identity.email }</span> 
                </div>
                
                <div className="account-item">
                <span className="account-label">No. HP </span> <span className="account-data">{ props.identity.whatsapp_no }</span> 
                </div>

                <div className="account-item">
                <span className="account-label">Password </span> <span className="account-data">**********</span> 
                </div>

                <div className="btn-account-modify-container">
                    <SecondaryButton size="sm" customClass="btn-account-modify" onClick={() => modifyAccount()}>
                        <FontAwesomeIcon icon={['fas', 'pencil']} /> Ubah Akun
                    </SecondaryButton>
                </div>
            </div>
        </>
    );
};

const AccountForm = (props) => {
    const navigate = useNavigate();
    const { fullname, email, whatsapp_no } = props.identity;
    const [ firstName, lastName ] = fullname.split(' ', 2);

    const [formData, setFormData] = useState({
        email: {
            label: "Email",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            value: email,
            required: true,
            disabled: true,
            placeholder: 'Alamat Email'
        },
        firstName: {
            label: "Nama Depan",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            value: firstName,
            required: true,
            placeholder: 'Nama Depan'
        },
        lastName: {
            label: "Nama Belakang",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            value: lastName,
            required: true,
            placeholder: 'Nama Belakang'
        },
        whatsapp_no: {
            label: "No. HP",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            prefix: "+62",
            value: whatsapp_no,
            required: true,
            placeholder: 'No. HP / Whatsapp'
        },
        // oldPassword: {
        //     label: "Ganti Password",
        //     ref: useRef(''),
        //     value: '',
        //     width: 12, // x < 12
        //     type: "password",
        //     required: true,
        //     placeholder: 'Password Lama'
        // },
        // newPassword: {
        //     label: "",
        //     ref: useRef(''),
        //     value: '',
        //     width: 12, // x < 12
        //     type: "password",
        //     required: true,
        //     placeholder: 'Password Baru'
        // },
        // confirmPassword: {
        //     label: "",
        //     ref: useRef(''),
        //     value: '',
        //     width: 12, // x < 12
        //     type: "password",
        //     required: true,
        //     placeholder: 'Konfirmasi Password Baru'
        // },
    });

    const [allowSubmit, setAllowSubmit] = useState(true);

    const formUpdate = (name, value) => {
        let filled = true;
        let data = formData;

        for(let key in data) {
            let item = data[key];
            let value = item.ref.current.value;

            if(['firstName', 'lastName', 'email'].includes(key)) {
                if(value === null || value === '') {
                    filled = false;
                    break;
                }
            }
        }

        setAllowSubmit(filled);
    };

    const saveAccount = () => {
        if(allowSubmit) {
            let requestData = {};
            let data = formData;

            for(let key in data) {
                let item = data[key];
                let value = item.ref.current.value;

                if(value !== '') {
                    requestData[key] = value;
                }
            }

            if(!objectIsEmpty(requestData)) {
                requestData.fullname = [requestData.firstName, requestData.lastName].join(' ');
                delete requestData.firstName;
                delete requestData.lastName;
            }

            if(requestData.hasOwnProperty('oldPassword')) {
                if(requestData.hasOwnProperty('newPassword') && requestData.hasOwnProperty('confirmPassword')) {
                    if(requestData.newPassword !== requestData.confirmPassword) {
                        data.newPassword.hasError = true;
                        data.confirmPassword.hasError = true;

                        data.newPassword.errorMessage = `New Password and Confirm Password are not matched!`;
                    } else {
                        delete requestData.confirmPassword;
                    }
                }
            }

            ( async() => {
                const response = await updateAccount(requestData);

                if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
                    props.getIdentity();

                    GaTracker({
                        action: `Update Account`,
                        label: `Account ${requestData.fullname} has been updated!`,
                    });

                    navigate('/profile/account', {
                        state: {
                            saveSuccess: 1,
                            message: response.message
                        }
                    });
                } else {
                    // Display error if possible;
                    for(let index in response.data) {
                        let item = response.data[index];
                        let { 
                            // actual, 
                            field, 
                            message, 
                            // type 
                        } = item;

                        if(field === 'fullname') {
                            data.firstName.hasError = true;
                            data.firstName.errorMessage = message;

                            data.lastName.hasError = true;
                            data.lastName.errorMessage = message;
                        // } else if(field === 'whatsapp_no') {
                        //     data.phoneNo.hasError = true;
                        //     data.phoneNo.errorMessage = message;
                        } else { 
                            if(data.hasOwnProperty(field)) {
                                data[field].hasError = true;
                                data[field].errorMessage = message;
                            }
                        }
                    }

                    setFormData({
                        ...data
                    });
                }
            } )();
        } else {
            console.log('Please fill required data!');
        }
    }

    return (
        <div className="row form-account-modify">
            {
                Object.keys(formData).map( (key, index) => {
                    let item = formData[key];

                    return <FormInput 
                                key={index} 
                                attribute={item}
                                name={key} 
                                onChange={ (name, value) => formUpdate(name, value) } 
                                customClass=""
                            />
                } )
            }
            <div className="col-lg-12">
                <PrimaryButton size="sm" disabled={!allowSubmit} customClass="btn-account-modify" onClick={ () => saveAccount() }>
                    Simpan
                </PrimaryButton>
            </div>
        </div>
    );
};

const MyAddress = () => {
    const contactData = {
        firstName: {
            label: "First Name",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        lastName: {
            label: "Last Name",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        phone: {
            label: "Phone Number/WA",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: 'No. Telp',
            prefix: '+62'
        },
    };

    const shippingData = {
        // email: {
        //     label: "",
        //     ref: useRef(''),
        //     value: '',
        //     width: 12, // x < 12
        //     type: "text",
        //     required: true,
        //     placeholder: 'Email'
        // },
        province: {
            label: "Provinsi",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Provinsi'},
                {'value': 1, 'label': 'DKI Jakarta'},
                {'value': 2, 'label': 'Bekasi'},
                {'value': 3, 'label': 'Depok'},
                {'value': 4, 'label': 'Bandung'}
            ],
        },
        regency: {
            label: "Kota",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kota/Kabupaten'},
                {'value': 1, 'label': 'Jakarta Barat'},
                {'value': 2, 'label': 'Jakarta Utara'},
                {'value': 3, 'label': 'Jakarta Timur'},
                {'value': 4, 'label': 'Jakarta Pusat'},
                {'value': 5, 'label': 'Jakarta Selatan'}
            ]
        },
        district: {
            label: "Kecamatan",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kecamatan'},
                {'value': 1, 'label': 'Kalideres'},
                {'value': 2, 'label': 'Tanjung Duren'},
                {'value': 3, 'label': 'Pluit'},
                {'value': 4, 'label': 'Sunter'}
            ]
        },
        village: {
            label: "Kelurahan",
            ref: useRef(0),
            value: 0,
            width: 6, // x < 12
            type: "select",
            required: true,
            options: [
                {'value': 0, 'label': 'Pilih Kelurahan'},
                {'value': 1, 'label': 'Pegadungan'},
                {'value': 2, 'label': 'Cengkareng'},
                {'value': 3, 'label': 'Penjaringan'},
                {'value': 4, 'label': 'Pluit'}
            ]
        },
        postalCode: {
            label: "Postal Code",
            ref: useRef(''),
            value: '',
            width: 6, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        streetAddress: {
            label: "Alamat Lengkap",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: true,
            placeholder: ''
        },
        famousLandmark: {
            label: "Famous Landmark",
            ref: useRef(''),
            value: '',
            width: 12, // x < 12
            type: "text",
            required: false,
            placeholder: ''
        },
    };
    const chosenAddress = useRef(0);

    return (
        <AddressSection
            useTitle={false} 
            contactData={contactData}
            shippingData={shippingData}
            chosenAddress={chosenAddress} 
            className="profile-address-list"
        />
    );
};

const MyOrder = () => {
    const [transactions, setTransactions] = useState({
        data: [],
        amount: 0
    });
    const [ transactionStatus, setTransactionStatus ] = useState(null);
    const [ start, setStart ] = useState(0);
    const txStatus = Init.constants.transactionStatus;
    const tabList = Object.entries(txStatus);
    const limit = 10;

    const { imageFolder } = Init.config;

    const productStorage = process.env.REACT_APP_API_PRODUCT_STORAGE;

    const PaginationList = () => {
        const arr = [];
        
        for( let i = 1 ; i <= Math.ceil(transactions.length / limit) ; i++ ) {
            arr.push(i);
        }

        return arr;
    }

    const prevPage = () => {
        changePage(Math.max(start - limit, 0));
        
        GaTracker({
            action: `Change Page`,
            label: `Change to previous transaction page in profile menu!`
        });
    }

    const changePage = (startNum) => {
        setStart(startNum);
    }

    const nextPage = () => {
        if(start + limit < transactions.amount) {
            changePage(start + limit);
            
            GaTracker({
                action: `Change Page`,
                label: `Change to next transaction page in profile menu!`
            });
        }
    }

    const loadTransactions = async (start) => {
        const response = await transactionAPI.getOrders(transactionStatus, start, limit);

        if(response.hasOwnProperty('errorCode') && response.errorCode === 0) {
            for(let index in response.data.data) {
                let item = response.data.data[index];

                // item.product_variant_id = 1;
                // item.variant_name = "Gelas Kotak";
                // item.product_code = "GK";
                // item.size_name = "Segitu";
                item.price = item.total;
                // item.quantity = 1;
                // item.photo_path = "http://localhost:3027/assets/images/header-image.png"
                
                // item.product_variant_id = item.transaction_products[0].product_variant_id;
                // item.variant_name = item.transaction_products[0].variant_name;
                // item.product_code = item.transaction_products[0].product_code;
                // item.size_name = item.transaction_products[0].size_name;
                // item.price = item.transaction_products[0].price;
                // item.quantity = item.transaction_products[0].quantity;
                // item.photo_path = item.transaction_products[0].photo_path !== null ? `${productStorage}${item.transaction_products[0].product_code}/${item.transaction_products[0].photo_path}` : `${imageFolder}placeholder.jpeg`;

                response.data.data[index] = item;
            }

            setTransactions(response.data);
        }
    }

    useEffect(() => {
        ( async() => {
            await loadTransactions(start);
        } )();
    }, [start]);

    useEffect(() => {
        if(start === 0) {
            ( async() => {
                await loadTransactions(start);
            } )();
        } else {
            setStart(0);
        }
    }, [transactionStatus]);

    useEffect( () => {
        window.scroll({
            behavior: 'smooth',
            top: 0
        });
    }, [transactions]);

    // useEffect(() => {
    //     setStart(0);
    // }, [transactions]);

    return (
        <div className="order-container">
            <div className="order-status">
                <button onClick={() => setTransactionStatus(null)} className={`order-status-item ${transactionStatus === null ? 'active' : ''}`}>
                    Semua Pesanan
                </button>
                {
                    tabList.map((item, index) => {
                        return (
                            <button key={index} onClick={() => setTransactionStatus(item[0])} className={`order-status-item ${transactionStatus === item[0] ? 'active' : ''}`}>
                                {
                                    item[1]
                                }
                            </button>
                        );
                    })
                }
            </div>
            <div className="order-list">
                {
                    transactions.data.map((item, index) => {
                        return (
                            <div key={index} className="order-item">
                                {/* 
                                    1. Status
                                    2. Product Info
                                    3. Total
                                    4. Button to detail
                                */}
                                <div className="order-item-status">
                                    <span className="order-status-label">
                                        <FontAwesomeIcon icon={['fas', 'clock']} /> { getFormattedDatetime(item.created_at, 'd M Y, H:i') }
                                    </span>
                                    <span className={`order-status-value ${item.transaction_status === 3 ? 'done' : ''}`}>
                                        {
                                            txStatus[item.status]
                                        }
                                    </span>
                                </div>
                                <div className="order-item-info">
                                    <div className="product-image-container">
                                        {/* <img src={item.photo_path} alt="" /> */}
                                        <FontAwesomeIcon className='icon' icon={['fas', 'money-bill']} />
                                    </div>
                                    <div className="product-info-container">
                                        <div className="product-name">
                                            {
                                                item.transaction_label
                                            }
                                        </div>
                                        <div className="product-attr">
                                            {/* <span className="size">
                                                Size: { item.size_name }
                                            </span> */}
                                            <span className="qty">
                                                Qty: { item.quantity } pcs
                                            </span>
                                            <span className="price">
                                                Price: { numberFormatting(item.price) }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-item-total">
                                    <span className="total-products">
                                        { item.total_item + (item.total_item > 1 ? ' items' : ' item') }
                                    </span>
                                    <span className="total-price">
                                        Order Total: <span className='price-highlight'>{ numberFormatting(item.total) }</span>
                                    </span>
                                </div>
                                {/* <Link to={`/order/${item.transaction_code}`} className="order-button-detail" onClick={ () => GaTracker({
                                    action: `Click transaction detail`,
                                    label: `Open transaction detail of trx code ${item.transaction_code}`
                                }) }>
                                    <span className="text">
                                        View more <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                                    </span>
                                </Link> */}
                            </div>
                        )
                    })
                }
            </div>
            <div className="order-pagination">
                <button className={`btn-pagination prev ${ start === 0 ? 'disabled' : '' }`} onClick={ () => prevPage() }>
                    <FontAwesomeIcon icon={[ 'fas', 'arrow-left' ]} /> Previous
                </button>
                {
                    PaginationList().map( (item, index) => {
                        return (
                            <button 
                                key={index} 
                                className={`btn-pagination btn-number ${ index * limit === start ? 'active' : ''}`} 
                                onClick={ () => changePage( index * limit ) }
                            >
                                { item } 
                            </button>
                        )
                    } )
                }
                <button className={`btn-pagination next ${ start + limit >= transactions.length ? 'disabled' : '' }`} onClick={ () => nextPage() }>
                    Next <FontAwesomeIcon icon={[ 'fas', 'arrow-right' ]} /> 
                </button>
            </div>
            {/* 
                1. Status Tabs
                2. Order List
                3. Pagination Number
            */}
        </div>
    );
};

const ProfilePage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ identity, setIdentity ] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        setIsLoading(true);
    }, [props.identity]);

    useEffect(() => {
        if(isLoading && !objectIsEmpty(props.identity)) {
            setIdentity({
                ...props.identity
            });
        }
    }, [isLoading, props.identity]);

    useEffect(() => {
        if(identity !== null) {
            setIsLoading(false);
        }
    }, [identity]);

    const routes = (props = {}) => {
        return [
            { activeOn: ["/profile/account", "/profile/edit-account"], path: "/account", title: 'Detail Akun', element: <MyAccount {...props} />, show: true },
            { activeOn: ["/profile/account", "/profile/edit-account"], path: "/edit-account", title: 'Detail Akun', element: <AccountForm {...props} />, show: false },
            { activeOn: ["/profile/address"], path: "/address", title: 'Alamat Saya', element: <MyAddress {...props} />, show: true },
            { activeOn: ["/profile/order"], path: "/order", title: 'Pesanan Saya', element: <MyOrder {...props} />, show: true },
            { path: "*", element: <ErrorPage /> }
        ];
    } 
    const routePath = routes(props);

    const element = useRoutes(routePath);
    const currentPath = routePath.find( item => `/profile${item.path}` === location.pathname);

    const contentTitle = currentPath !== undefined && currentPath.hasOwnProperty('title') ? currentPath.title : '';

    return (
        <div className='profile-page'>
            {
                /*
                    Two parts:
                    1. Sidebar menu
                    2. Content
                */
                isLoading ? (
                    <div className="profile-menu">
                        <LoadingPage />
                    </div>
                ) : (
                    identity === null ? (
                        ''
                    ) : (
                        <>
                            <div className="profile-menu">
                                <h1 className='profile-menu-title'>
                                    Akun Saya
                                </h1>
        
                                <div className="profile-menu-divisor">
                                    
                                </div>
        
                                <div className="profile-menu-list">
                                    {
                                        routePath.map( (item, index) => {
                                            let fullPath = `/profile${item.path}`;
        
                                            return item.hasOwnProperty('show') && item.show ? (
                                                <Link key={index} to={fullPath} className={`profile-menu-item ${item.activeOn.includes(location.pathname) ? 'active' : ''}`}>
                                                    { item.title }
                                                </Link>
                                            ) : ''
                                        } )
                                    }
                                </div>
                            </div>
                            <div className="profile-content">
                                <h2 className='profile-content-title'>
                                    {
                                        contentTitle
                                    }
                                </h2>
                                <div className="profile-content-divisor">
        
                                </div>
                                {
                                    element
                                }
                            </div>
                        </>
                    )
                )
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);