import api from './API';

const basePath = 'shipping';
const pathList = {
    courier: '/courier',
    getQuotation: '/get-quotation'
};

for(let key in pathList) {
    pathList[key] = basePath + pathList[key];
}

const courierList = async () => {
    try {
        const apiPath = pathList.courier;
        const courier = await api.getCall(apiPath);

        return courier;
    } catch(e) {
        console.log(e.toString());
    }
}

const getQuotation = async (
    courierId, 
    provinceCode, regencyCode, districtCode, villageCode,
    address, 
    latitude, longitude, 
    zipCode,
    items
) => {
    try {
        const apiPath = pathList.getQuotation;
        const quotations = await api.getCall(apiPath, null, {
            courierId, 
            provinceCode, regencyCode, districtCode, villageCode,
            address, 
            latitude, longitude, 
            zipCode,
            items
        });

        return quotations;
    } catch(e) {
        console.log(e.toString());
    }
}

const shippingAPI = {
    courierList,
    getQuotation
}

export default shippingAPI;