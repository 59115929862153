import { openDB } from 'idb';

const DB_NAME = "fileDB";
const STORE_NAMES = {
    designAttachment: 'designAttachment'
}

const initDB = async (storeName) => {
    return openDB(
        DB_NAME,
        1,
        {
            upgrade(db) {
                if( !db.objectStoreNames.contains(storeName) ) {
                    db.createObjectStore(storeName, { keyPath: 'id' });
                }
            }
        }
    );
}

const addFile = async (id, file) => {
    const db = await initDB(STORE_NAMES.designAttachment);
    await db.put(STORE_NAMES.designAttachment, { id, file });
}

const getFile = async (id) => {
    const db = await initDB(STORE_NAMES.designAttachment);
    const dbFile = await db.get(STORE_NAMES.designAttachment, id);

    return dbFile.file;
}

const deleteFile = async (id) => {
    const db = await initDB(STORE_NAMES.designAttachment);
    await db.delete(STORE_NAMES.designAttachment, id);
}

export default initDB;
export const fileManager = {
    addFile,
    getFile,
    deleteFile
}
